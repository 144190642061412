.fullscreen-container {
    position: relative;
    width: 100vw;
    height: 100vh;
    /* overflow: hidden; */
    text-align: center;
  }
  
  .fullscreen-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .moving-text {
    position: absolute;
    bottom: 100px; /* Initial position */
    left: 50%;
    transform: translateX(-50%);
    color: white;
    animation: moveUp 5s ease-in-out forwards;
  }

  .moving-text .hoaks-name {
    font-size: 4rem;
  }

  .moving-text .scroll-down-arrow {
    height: 20px;
    padding: 20px;
  }
  
  @keyframes moveUp {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      background: lightslategray
    }
  }
  
  .hoaks-explanation {
    color: black;
    background-color: cornflowerblue;
    padding: 100px;
    margin: 100px;

  }

  .explanation-break {
    clear:both;
    height: 100px;
  }
  .hoaks-explanation h2 {
    font-size: 2em;
    color: white;
    padding: 25px;
  }

  .hoaks-explanation p {
    font-size: 1.5em;
  }

  .show-another {
    color: black;
    padding: 100px;
    font-size: 1.5em;
  }

  .show-another .see-another-link {
    color: blue;
    text-decoration: underline;
  }